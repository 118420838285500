<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Electrochemistry Experiment: Results - Part B</h2>

      <p class="mb-5">
        Fill in the information in the data table below summarizing your results from Part B of the
        experiment. Please use the link
        <a
          href="https://openstax.org/books/chemistry-2e/pages/l-standard-electrode-half-cell-potentials"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >
        for a list of standard reduction potentials for the determination of
        <stemble-latex content="$\text{E}_\text{cell,theor}.$" />
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\textbf{Variable}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\textbf{Value}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><stemble-latex content="$\text{i) V}_\ce{CuSO4}$" /> in Vol Flask (mL)</td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.volCuSO41"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\ce{ii) [CuSO4]}_\text{dil}~\text{(M)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.concFlask1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{iii) E}_\text{cell,obs}$" />
              <stemble-latex content="$\text{(V)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.EcellObs"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{iv) E}_\text{cell,theor}$" />
              <stemble-latex content="$\text{(V)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{v) }\%\,\text{Error}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.pctError"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ubcElectrochemResultsB',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        volCuSO41: null,
        concFlask1: null,
        EcellObs: null,
        EcellTheoretical: null,
        pctError: null,
      },
    };
  },
};
</script>
<style scoped></style>
